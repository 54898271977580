import * as React from "react";
import { Masthead } from "molecules";
import { Shell } from "layout";
import "scss/main.scss";

const NewGenApplication = ({
    data: { prismicSite },
    location: { pathname },
}) => {
    return (
        <Shell
            pathname={pathname}
            seoTitle="Oxbury New Gen"
            siteData={prismicSite}
        >
            <main className="l-grid__main" id="main-content">
                <Masthead
                    image={{
                        alt: "",
                        dimensions: {
                            height: 1080,
                            width: 1920,
                        },
                        url:
                            "https://images.prismic.io/oxbury-fs-plc/114bc142-931d-458c-930e-e40d9cbd4010_NewGen-Website-4.jpg?auto=compress,format",
                    }}
                    strapline="Oxbury New Gen"
                    title="Application Form"
                />
            </main>
            <iframe
                src="https://oxbury.formstack.com/forms/funding_future_farmers"
                title="Oxbury New Gen"
                className="p-new-gen-application__form"
            ></iframe>
        </Shell>
    );
};

export const query = graphql`
    query {
        prismicSite {
            ...FooterQuery
        }
        prismicSite {
            ...HeaderQuery
        }
        prismicSite {
            ...NavQuery
        }
    }
`;

export default NewGenApplication;
